<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="custom-drawer"
      width="18.75rem"
      color="gray2"
      height="100%"
      dark
      app
    >
      <div class="d-flex align-center px-4 py-2">
        <v-menu
          content-class="mt-2"
          min-width="12.5rem"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              offset-x="30"
              offset-y="8"
              :color="_currentStatus"
              bottom
              dot
            >
              <v-avatar
                v-bind="attrs"
                v-on="on"
                class="mr-6"
                size="2.5rem"
                color="gray4"
                rounded
              >
                <v-img v-if="user.photo" :src="user.photo" />
                <v-icon v-else>mdi-account-outline</v-icon>
              </v-avatar>
            </v-badge>
          </template>

          <v-list class="pt-0">
            <v-list-item>
              <v-list-item-title>Definir status</v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item
              v-for="(item, i) in status"
              :key="i"
              :class="{ 'primary--text': user?.status === item.value }"
              dense
              @click="changeStatus(item.value)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon :color="item.color" x-small>mdi-circle</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer />

        <v-btn class="custom-btn mr-2" small icon>
          <v-icon small @click="notificationBell">mdi-bell</v-icon>
        </v-btn>

        <v-menu
          content-class="mt-2"
          min-width="12.5rem"
          :close-on-content-click="false"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="custom-btn" small icon>
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="handleDialog()">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-account</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Meu Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item @click="signOut()">
              <v-list-item-icon class="mr-2">
                <v-icon small>mdi-logout</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <section class="d-flex flex-column pb-4">
        <v-divider class="gray1 mb-4" />

        <div
          v-for="(item, i) in _routes"
          :key="i"
          v-show="item.modules.includes(_userType)"
        >
          <router-link
            v-if="item.path"
            v-slot="{ href, navigate, isActive }"
            :to="item.path"
            custom
          >
            <v-list-item
              class="px-4"
              :class="isActive ? 'gray3' : 'transparent'"
              :href="href"
              @click="navigate"
            >
              <v-list-item-avatar rounded>
                <v-icon v-if="item.icon">
                  {{ item.icon }}
                </v-icon>

                <v-avatar v-else size="2rem" color="gray4" rounded />
              </v-list-item-avatar>

              <v-list-item-title class="gray5--text">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>

          <v-list-item v-else-if="item.href" class="px-4" :href="item.href">
            <v-list-item-avatar rounded>
              <v-icon v-if="item.icon">
                {{ item.icon }}
              </v-icon>

              <div v-else-if="item.svg" v-html="item.svg"></div>

              <v-avatar v-else size="2rem" color="gray4" rounded />
            </v-list-item-avatar>

            <v-list-item-title class="gray5--text">
              {{ item.label }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            v-else
            class="px-4"
            @click="handleMenuAction(item.action)"
          >
            <v-list-item-avatar rounded>
              <v-icon v-if="item.icon">
                {{ item.icon }}
              </v-icon>

              <div v-else-if="item.svg" v-html="item.svg"></div>

              <v-avatar v-else size="2rem" color="gray4" rounded />
            </v-list-item-avatar>

            <v-list-item-title class="gray5--text">
              {{ item.label }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </section>

      <ProfileDialog
        ref="profileDialog"
        :loading="loading.user"
        @update="handleUpdateUser"
      />
    </v-navigation-drawer>

    <v-dialog v-model="unityDialog" max-width="25rem" persistent>
      <v-card class="pa-6 rounded-lg">
        <div class="d-flex align-center mb-4">
          <div class="text-body-1 font-weight-bold">Selecione uma unidade</div>

          <v-spacer />

          <v-btn icon @click="cancelUnity()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid.unity" ref="formUnity" autocomplete="off">
          <v-select
            v-model="form.unity"
            :items="unities"
            placeholder="Selecione uma unidade"
            item-value="name"
            item-text="name"
            :rules="_rules"
            outlined
          ></v-select>
        </v-form>

        <v-btn
          class="text-none"
          color="primary"
          @click="submitUnity()"
          block
          large
        >
          Confirmar
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getCourts } from "@/services/courts";
import ProfileDialog from "./partials/profileDialog/ProfileDialog.vue";
import { updateUser } from "@/services/users";
import { handleAlert } from "@/utils";

export default {
  name: "NavDrawer",

  components: {
    ProfileDialog,
  },

  data() {
    return {
      drawer: true,
      loading: {
        user: false,
        unities: false,
      },
      status: [
        {
          color: "green",
          value: "online",
          label: "Disponível",
        },
        {
          color: "red",
          value: "busy",
          label: "Ocupado",
        },
        {
          color: "amber",
          value: "away",
          label: "Ausente",
        },
        {
          color: "grey",
          value: "offline",
          label: "Invisível",
        },
      ],
      unityDialog: false,
      unities: [],
      valid: {
        unity: false,
      },
      form: {
        unity: "",
      },
    };
  },

  computed: {
    ...mapState(["user"]),

    _userType() {
      return this.user?.type || "attendant";
    },
    _currentStatus() {
      const status = this.user.status || "online";
      const result = this.status.find((el) => el.value === status);
      return result ? result.color : "green";
    },
    _isAdmin() {
      return this.user.type !== "attendant";
    },

    _routes() {
      return [
        {
          label: "Visão Geral",
          path: "/home",
          icon: "mdi-view-dashboard",
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Balcões",
          path: "/counters",
          icon: "mdi-view-day-outline",
          modules: ["coordinator", "director"],
        },
        {
          label: "Usuários",
          path: "/users",
          icon: "mdi-account-multiple-outline",
          modules: ["coordinator", "director"],
        },
        {
          label: "Relatórios",
          path: "/reports",
          icon: "mdi-chart-areaspline",
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Fila de Atendimento",
          path: "/attendance",
          icon: "mdi-chart-box",
          modules: ["attendant", "coordinator", "director"],
        },
        /*  {
          label: "Audiências",
          action: "audience",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gavel"><path d="m14.5 12.5-8 8a2.119 2.119 0 1 1-3-3l8-8"/><path d="m16 16 6-6"/><path d="m8 8 6-6"/><path d="m9 7 8 8"/><path d="m21 11-8-8"/></svg>`,
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Transcrições",
          href: "https://reeducation-frontend-jfijholz5a-uc.a.run.app/transcription-list",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>`,
          modules: ["attendant", "coordinator", "director"],
        },
        {
          label: "Upload de audiências",
          href: "https://reeducation-frontend-jfijholz5a-uc.a.run.app/group-media-list",
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2m0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8"/><path fill="currentColor" d="M12.34 7.45a1.7 1.7 0 0 0-1.85-.3a1.6 1.6 0 0 0-1 1.48v6.74a1.6 1.6 0 0 0 1 1.48a1.7 1.7 0 0 0 .69.15a1.74 1.74 0 0 0 1.16-.45L16 13.18a1.6 1.6 0 0 0 0-2.36Zm-.84 7.15V9.4l2.81 2.6Z"/></svg>`,
          modules: ["attendant", "coordinator", "director"],
        }, */
      ];
    },

    _rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setUser"]),

    handleDrawer() {
      this.drawer = !this.drawer;
    },

    changeStatus(value) {
      this.$emit("change-status", value);
    },

    signOut() {
      this.$emit("sign-out");
    },

    handleMenuAction(action) {
      if (action === "audience") {
        this.getCourts();
        this.unityDialog = true; // href: "https://audiencias-app-673194685875.us-central1.run.app/escolher-vara",
      }
    },

    submitUnity() {
      if (!this.$refs.formUnity.validate()) return;

      const unity = this.form.unity
        .trim()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const newName = unity
        .toLowerCase()
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-");

      window.location.href = `https://audiencias-app-673194685875.us-central1.run.app/admin/${newName}`;
    },

    cancelUnity() {
      this.unityDialog = false;
    },

    async getCourts() {
      try {
        this.loading.unities = true;

        const payload = {
          limit: 100,
          offset: 0,
          enableAudience: true,
        };

        const { data } = await getCourts(payload);

        this.unities = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.unities = false;
      }
    },
    handleDialog() {
      this.$refs.profileDialog.open();
    },
    notificationBell() {
      this.$emit("notification-bell");
    },
    async handleUpdateUser(body) {
      this.loading.user = true;

      try {
        const payload = structuredClone(body);
        const userID = payload._id || this.user._id;
        delete payload._id;

        const { data } = await updateUser(userID, payload);

        this.setUser(data);

        this.handleAlert("Perfil atualizado.", "success");
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          error?.message ||
          "Erro ao atualizar perfil";
        console.log(message);
        this.handleAlert(message, "error");
      } finally {
        this.loading.user = false;
      }
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
