<template>
  <v-navigation-drawer
    v-model="_internalValue"
    temporary
    absolute
    right
    v-bind="$attrs"
    v-on="$listeners"
    @input="emitInput"
    width="400px"
  >
    <v-card :elevation="0">
      <v-card-title>
        <slot name="title">
          <div class="d-flex flex-row justify-space-between">
            <div class="d-flex flex-row align-center">
              <v-icon class="mr-2" color="primary" @click="close">
                mdi-chevron-left
              </v-icon>

              <span class="text-h6">Filtros</span>
            </div>
          </div>
        </slot>
      </v-card-title>

      <v-card-text>
        <slot>
          <v-form @submit.prevent="submit">
            <slot name="content">
              <div class="d-flex flex-column pa-2">
                <ItemSelector
                  class="mt-2"
                  v-if="_isDirector"
                  v-model="selectors.groups.selected"
                  :items="selectors.groups.items"
                  :label="selectors.groups.label"
                  :placeholder="selectors.groups.placeholder"
                  :count="selectors.groups.count"
                  :multiple="selectors.groups.multiple"
                  :clearable="selectors.groups.clearable"
                  :item-value="selectors.groups.itemValue"
                  :item-text="selectors.groups.itemText"
                  @search="selectors.groups.onSearch"
                  @paginate="selectors.groups.onPaginate"
                />

                <ItemSelector
                  class="mt-2"
                  v-if="_isDirector || _isCoordinator"
                  v-model="selectors.subgroups.selected"
                  :items="selectors.subgroups.items"
                  :label="selectors.subgroups.label"
                  :placeholder="selectors.subgroups.placeholder"
                  :count="selectors.subgroups.count"
                  :multiple="selectors.subgroups.multiple"
                  :clearable="selectors.subgroups.clearable"
                  :item-value="selectors.subgroups.itemValue"
                  :item-text="selectors.subgroups.itemText"
                  :disabled="
                    !_isCoordinator && !selectors.groups.selected?.length
                  "
                  @search="selectors.subgroups.onSearch"
                  @paginate="selectors.subgroups.onPaginate"
                />

                <ItemSelector
                  class="mt-2"
                  v-model="selectors.desks.selected"
                  :items="selectors.desks.items"
                  :label="selectors.desks.label"
                  :placeholder="selectors.desks.placeholder"
                  :count="selectors.desks.count"
                  :multiple="selectors.desks.multiple"
                  :clearable="selectors.desks.clearable"
                  :item-value="selectors.desks.itemValue"
                  :item-text="selectors.desks.itemText"
                  :disabled="
                    !_isAttendant && !selectors.subgroups.selected?.length
                  "
                  @search="selectors.desks.onSearch"
                  @paginate="selectors.desks.onPaginate"
                />

                <ItemSelector
                  v-if="_isDirector || _isCoordinator"
                  v-model="selectors.attendant.selected"
                  class="mt-2"
                  :items="selectors.attendant.items"
                  :label="selectors.attendant.label"
                  :placeholder="selectors.attendant.placeholder"
                  :count="selectors.attendant.count"
                  :multiple="selectors.attendant.multiple"
                  :clearable="selectors.attendant.clearable"
                  :item-value="selectors.attendant.itemValue"
                  :item-text="selectors.attendant.itemText"
                  :disabled="
                    !_isCoordinator && !selectors.desks.selected?.length
                  "
                  :showMore="false"
                  @search="selectors.attendant.onSearch"
                  @paginate="selectors.attendant.onPaginate"
                />
              </div>
            </slot>
          </v-form>
        </slot>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ItemSelector from "../itemSelector/ItemSelector.vue";
import { getJurisdictions } from "@/services/jurisdictions";
import { getCourts } from "@/services/courts";
import { getCounters } from "@/services/counters";
import { handleAlert } from "@/utils";

export default {
  name: "FilterDrawer",

  components: {
    ItemSelector,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["user", "filters"]),

    _internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    _isDirector() {
      return this.user?.type === "director";
    },
    _isCoordinator() {
      return this.user?.type === "coordinator";
    },
    _isAttendant() {
      return this.user?.type === "attendant";
    },
  },

  watch: {
    "selectors.groups.selected": {
      handler(value) {
        // console.log("updated:groups");
        this.setFiltersGroups(value);
        this.selectors.subgroups.page = 0;
        this.selectors.subgroups.count = 0;
        this.selectors.subgroups.items = [];
        this.selectors.subgroups.selected = [];

        if (value?.length) {
          this.handleFetchSubgroups();
        }
      },
      deep: true,
    },
    "selectors.subgroups.selected": {
      handler(value) {
        // console.log("updated:subgroups");
        this.setFiltersSubgroups(value);
        this.selectors.desks.page = 0;
        this.selectors.desks.count = 0;
        this.selectors.desks.items = [];
        this.selectors.desks.selected = [];

        if (value?.length) {
          this.handleFetchDesks();
        }
      },
      deep: true,
    },
    "selectors.desks.selected": {
      handler(value) {
        // console.log("updated:desks");
        this.setFiltersDesks(value);

        this.selectors.attendant.page = 0;
        this.selectors.attendant.count = 0;
        this.selectors.attendant.items = [];
        this.selectors.attendant.selected = [];

        if (value?.length) {
          this.handleFetchAttendants();
        }
      },
      deep: true,
    },
    "selectors.attendant.selected": {
      handler(value) {
        // console.log("updated:attendant");
        this.setFiltersAttendants(value);
      },
      deep: true,
    },
  },

  data() {
    return {
      selectors: {
        groups: {
          label: this.$t("groups"),
          placeholder: `Digite para buscar ${this.$t("groups")}...`,
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: [],
          loading: false,
          multiple: true,
          clearable: true,
          count: 0,
          page: 0,
          onSearch: this.onSearchGroups,
          onPaginate: this.fetchMoreDesks,
        },
        subgroups: {
          label: this.$t("subgroups"),
          placeholder: `Digite para buscar ${this.$t("subgroups")}...`,
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: [],
          loading: false,
          multiple: true,
          clearable: true,
          count: 0,
          page: 0,
          onSearch: this.onSearchSubgroups,
          onPaginate: this.fetchMoreSubgroups,
        },
        desks: {
          label: this.$t("desks"),
          placeholder: `Digite para buscar ${this.$t("desks")}...`,
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: [],
          loading: false,
          multiple: true,
          clearable: true,
          count: 0,
          page: 0,
          onSearch: this.onSearchDesks,
          onPaginate: this.fetchMoreDesks,
        },
        attendant: {
          label: "Atendente",
          placeholder: "Digite para buscar Atendentes...",
          itemValue: "_id",
          itemText: "name",
          items: [],
          selected: [],
          loading: false,
          multiple: false,
          clearable: true,
          count: 0,
          page: 0,
          onSearch: this.onSearchAttendants,
          onPaginate: this.fetchMoreAttendants,
        },
      },
    };
  },

  methods: {
    ...mapActions([
      "setFiltersGroups",
      "setFiltersSubgroups",
      "setFiltersDesks",
      "setFiltersAttendants",
    ]),
    submit() {
      this.$emit("submit");
    },
    emitInput(value) {
      this.$emit("input", value);
    },
    close() {
      this._internalValue = false;
    },
    async handleFetchGroups(search = "", increment = false) {
      if (!this._isDirector) {
        return;
      }

      this.selectors.groups.loading = true;

      try {
        const groupIDs = this.selectors.groups.selected.map((item) => item._id);

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.groups.page * 10,
          personID: this.user._id,
          ...(!!groupIDs?.length && { nin: groupIDs }),
          ...(!!search?.length && { search }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getJurisdictions(payload);

        this.selectors.groups.count = Number(count);

        if (increment) {
          this.selectors.groups.items.push(...data);
        } else {
          this.selectors.groups.items = data;
        }
      } catch (error) {
        console.log("Falha ao obter grupos", error);
        this.handleAlert(
          error?.data?.message || "Falha ao obter grupos",
          "error"
        );
      } finally {
        this.selectors.groups.loading = false;
      }
    },

    async handleFetchSubgroups(search = "", increment = false) {
      if (!this._isDirector && !this._isCoordinator) {
        return;
      }

      this.selectors.subgroups.loading = true;

      try {
        const groupIDs = this.selectors.groups.selected?.map((s) => s._id);
        const subgroupIDs = this.selectors.subgroups.selected?.map(
          (s) => s._id
        );

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.subgroups.page * 10,
          ...(subgroupIDs?.length && { nin: subgroupIDs }),
          ...(this._isCoordinator && {
            personID: this.user._id,
            directOnly: true,
          }),
          ...(!!groupIDs?.length && { groupIDs }),
          ...(!!search?.length && { search }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getCourts(payload);

        this.selectors.subgroups.count = Number(count);

        if (increment) {
          this.selectors.subgroups.items.push(...data);
        } else {
          this.selectors.subgroups.items = data;
        }
      } catch (error) {
        console.log("Falha ao obter subgrupos", error);
        this.handleAlert(
          error?.data?.message || "Falha ao obter subgrupos",
          "error"
        );
      } finally {
        this.selectors.subgroups.loading = false;
      }
    },

    async handleFetchDesks(search = "", increment = false) {
      if (!this._isAttendant && !this.selectors.subgroups.selected?.length) {
        return;
      }

      this.selectors.desks.loading = true;

      try {
        const subgroupIDs = this.selectors.subgroups.selected?.map(
          (s) => s._id
        );
        const deskIDs = this.selectors.desks.selected?.map((s) => s._id);

        const payload = {
          sortBy: "name",
          sortOrder: "asc",
          limit: 10,
          offset: this.selectors.desks.page * 10,
          ...(deskIDs?.length && { nin: deskIDs }),
          ...(this._isAttendant && {
            personID: this.user._id,
            directOnly: true,
          }),
          ...(!!search?.length && { search }),
          ...(!!subgroupIDs?.length && { subgroupIDs }),
        };
        const {
          data,
          headers: { "x-count": count },
        } = await getCounters(payload);

        this.selectors.desks.count = Number(count);

        if (increment) {
          this.selectors.desks.items.push(...data);
        } else {
          this.selectors.desks.items = data;
        }
      } catch (error) {
        this.handleAlert(
          error?.data?.message || `Falha ao obter ${this.$t("desks")}`,
          "error"
        );
      } finally {
        this.selectors.desks.loading = false;
      }
    },

    async handleFetchAttendants(search = "") {
      if (!this._isAttendant && !this.selectors.desks.selected?.length) {
        return;
      }

      this.selectors.attendant.loading = true;

      try {
        let attendants = Array.from(
          this.selectors.desks.selected
            .reduce((map, item) => {
              item.attendants.forEach((attendant) => {
                map.set(attendant._id, attendant);
              });
              return map;
            }, new Map())
            .values()
        );

        if (search) {
          attendants = attendants.filter((el) =>
            el.name.toLowerCase().includes(search.toLowerCase())
          );
        }

        this.selectors.attendant.count = Number(attendants.length);

        this.selectors.attendant.items = structuredClone(attendants);
      } catch (error) {
        this.handleAlert(
          error?.data?.message || `Falha ao obter atendentes`,
          "error"
        );
      } finally {
        this.selectors.attendant.loading = false;
      }
    },

    onSearchGroups(search) {
      this.selectors.groups.page = 0;
      this.selectors.groups.count = 0;
      this.selectors.groups.items = [];

      this.handleFetchGroups(search);
    },

    onSearchSubgroups(search) {
      this.selectors.subgroups.page = 0;
      this.selectors.subgroups.count = 0;
      this.selectors.subgroups.items = [];

      this.handleFetchSubgroups(search);
    },

    onSearchDesks(search) {
      this.selectors.desks.page = 0;
      this.selectors.desks.count = 0;
      this.selectors.desks.items = [];

      this.handleFetchDesks(search);
    },

    onSearchAttendants(search) {
      this.selectors.attendant.page = 0;
      this.selectors.attendant.count = 0;
      this.selectors.attendant.items = [];

      this.handleFetchAttendants(search);
    },

    fetchMoreGroups(search) {
      this.selectors.groups.page++;
      this.handleFetchGroups(search, true);
    },

    fetchMoreSubgroups(search) {
      this.selectors.subgroups.page++;
      this.handleFetchSubgroups(search, true);
    },

    fetchMoreDesks(search) {
      this.selectors.desks.page++;
      this.handleFetchDesks(search, true);
    },

    fetchMoreAttendants(search) {
      this.selectors.attendant.page++;
      this.handleFetchAttendants(search, true);
    },

    handleAlert,
  },

  mounted() {
    this.handleFetchAttendants();

    if (this._isDirector) {
      this.handleFetchGroups();
    }

    if (this._isCoordinator) {
      this.handleFetchSubgroups();
    }

    if (this._isAttendant) {
      this.handleFetchDesks();
    }
  },

  beforeMount() {
    if (this.filters?.desks?.length) {
      this.selectors.desks.selected = this.filters.desks;
    }

    if (this.filters?.subgroups?.length) {
      this.selectors.subgroups.selected = this.filters.subgroups;
    }

    if (this.filters?.groups?.length) {
      this.selectors.groups.selected = this.filters.groups;
    }
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
