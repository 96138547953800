import { getSocket, connectSocket } from "./index";
import { eventBus } from "@/eventBus.js";

function initializeListeners(socket) {
  socket.on("attendance:finished", (id) => {
    eventBus.$emit("attendance:finished", id);
  });

  socket.on("attendance:transferred", (id) => {
    eventBus.$emit("attendance:transferred", id);
  });

  socket.on("attendance:queue", (data) => {
    eventBus.$emit("attendance:queue", data);
  });

  socket.on("attendance:request", (data) => {
    eventBus.$emit("attendance:request", data);
  });

  socket.on("attendance-messages", (data) => {
    eventBus.$emit("attendance-messages", data);
  });

  socket.on("attendance:new", (data) => {
    eventBus.$emit("attendance:new", data);
  });

  socket.on("attendance:started", (data) => {
    eventBus.$emit("attendance:started", data);
  });

  socket.on("attendance:induct", (data) => {
    eventBus.$emit("attendance:induct", data);
  });

  socket.on("pong", () => {
    console.log("pong");
  });
}

export async function startAttendance(data, callback) {
  const socket = getSocket();
  if (socket) {
    socket.emit("attendance:start", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

export async function attendanceNewMessage(data, callback) {
  const socket = getSocket();
  if (socket) {
    socket.emit("attendance-messages:new", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

export async function attendanceFinish(data, callback) {
  const socket = getSocket();
  if (socket) {
    console.log("attendance:finish");
    socket.emit("attendance:finish", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

export async function attendanceJoin(data, callback) {
  const socket = getSocket();
  if (socket) {
    socket.emit("attendance:join", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

export async function transferAttendance(data, callback) {
  const socket = getSocket();
  if (socket) {
    socket.emit("attendance:transfer", data, callback);
  } else {
    console.error("Socket não está conectado.");
  }
}

// Chamada para garantir que os listeners sejam inicializados quando o socket estiver conectado
export function ensureSocketListeners() {
  const socket = getSocket();
  if (socket) {
    initializeListeners(socket);
  } else {
    console.warn("Socket ainda não está conectado. Tentando reconectar...");
    const connectedSocket = connectSocket();
    if (connectedSocket) {
      initializeListeners(connectedSocket);
    }
  }
}

export async function pingEmit() {
  const socket = getSocket();

  if (socket) {
    socket.emit("ping");
  } else {
    console.error("Socket não está conectado.");
  }
}
